import React, { useEffect, useState, useRef } from 'react';
import { Form, Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { userService } from '../_services';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import io from "socket.io-client";
import { config } from '../config/config';
import { JitsiMeeting } from '@jitsi/react-sdk';
import { Loader } from './Loader';
// import VideoErrorThumbnail from '../images/video-error-icon.png';
// import { IMAGE_REGEX } from '../config/constants';
import Swal from 'sweetalert2';
import { jitsiConfig, interfaceJitsi } from '../config/config';
import { returnTotalPages } from '../utils/pagination';

// MODAL
import PopUpModal from '../modals';
import VideoError from '../images/image.png';


const FriendChat = () => {
    const jitsiContainerRef = useRef(null);
    const socket = useRef(null) as any
    const [show, setShow] = useState("none");
    const [room, setRoom] = useState("");
    const [data, setData] = useState([]) as any;
    const [sendMsg, setMsg] = useState("");
    const [otherUser, setOtherUser] = useState("") as any;
    const [removeMsgs, setRemoveMsgs] = useState([]) as Array<any>;
    const [temp, setTemp] = useState(0);
    const [showCall, setCallModal] = useState(false);
    const [jitsiRoom, setJitsiRoom] = useState("");
    const [play, setPlay] = useState("") as any;
    const [selectedFile, setSelectedFile] = useState({}) as any;
    const [selectedImage, setSelectedImage] = useState('') as any;
    const [selectedVideo, setSelectedVideo] = useState('');
    const [popUpModal, setPopUpModal] = useState({
        status: false,
        modalType: ''
    });
    const [paginationObj, setPaginationObj] = useState({
        page_no: 0,
        total_page: 0
    });

    const [isPaginationTrigger, setIsPaginationTrigger] = useState(false);

    let history = useHistory();
    const apiRef = useRef(null);
    const chatRowRef = useRef(null);

    let user_id = localStorage.getItem("user_id") as any;
    let username = localStorage.getItem("username") as any;

    useEffect(() => {
        setShow("flex")
        let search = window.location.search;
        let params = new URLSearchParams(search);
        let roomId = params.get("room") as any;
        let startvc = params.get("startvc") as any;
        var other_user = params.get("uid") as any;
        var JRoom = params.get("uid") as any;
        if (!roomId || !other_user) {
            toast.error("Unknown error occured.");
            history.push("/friends");
        };
        roomId = atob(roomId);
        other_user = atob(other_user);
        setRoom(roomId);
        setOtherUser(other_user);
        socket.current = io(`${config.chatSocket}`, {
            reconnection: true,
            reconnectionDelay: 1000,
            reconnectionDelayMax: 5000,
            reconnectionAttempts: Infinity
        });
        socket.current.on('connect', () => {
            console.log('connected to socket');
        });
        socket.current.emit("joinroom", roomId);
        socket.current.emit("joinroom", "VCR-" + user_id);

        let chatParams = {
            other_id: other_user,
            page_no: `${paginationObj.page_no}`
        } as any;

        getChat(chatParams);

        if (startvc) {
            setJitsiRoom(JRoom);
            startCall();
        }
        let element = document.getElementById("messageCompose");
        if (element) {
            window.scrollTo(0, element?.clientHeight);
        }
    }, []);

    if (socket.current) {
        socket.current.on("message", (recvData: any) => {
            let recvMsgId = recvData.id
            var temp = data
            var msg = recvData.message
            var findMsg = temp.find((el: any) => el.id == recvMsgId)
            if (!findMsg) {
                if (msg.endsWith("mp4")) {
                    recvData.thumbpath = msg.replace("png")
                }
                if (msg.endsWith("mov")) {
                    recvData.thumbpath = msg.replace("png")
                }
                recvData.timeAgo = 'just now'
                temp.push(recvData)
                setData(temp)
                setShow("none");
                scrollToBottom()
            }
        })
        socket.current.on("deleteMessages", (recvData: any) => {
            setShow("none")
            var msgs = recvData.messsage_ids.split(",")
            var tempmsgs = data
            msgs.forEach((el: any) => {
                for (let index = 0; index < tempmsgs.length; index++) {
                    const element = tempmsgs[index];
                    if (element.id == el) {
                        tempmsgs.splice(index, 1)
                    }
                }
            });
            setData(tempmsgs)
            setRemoveMsgs([] as Array<any>)
            setTemp(0)
            scrollToBottom()
        })
        socket.current.on("rejectcall", (resp: any) => {
            if (resp.call_status == 1) {
                setCallModal(false)
            }
        })
    }

    // get API for chat
    function getChat(chatParams: any) {
        userService.getChat(chatParams).then(resp => {
            if (resp.data.data) {
                if (paginationObj.page_no > 0) {
                    if (data.length) {
                        data.unshift(...(resp.data.data.reverse()));
                        setData(data);
                        let element = document.getElementById('chatBox') as HTMLBodyElement;
                        if (element) {
                            element.scrollTo({
                                top: 60,
                                left: 0,
                                behavior: 'smooth'
                            });
                        }
                    } else {
                        setData(resp.data.data.reverse);
                        scrollToBottom();
                    }
                } else {
                    setData(resp.data.data.reverse());
                    scrollToBottom();
                }
                let totalPages = returnTotalPages(resp.data.total_record, 10);
                handlePagination('total_page', totalPages)
            } else {
                handlePagination('total_page', 0);
                setData([]);
            }
            setShow("none");
            setIsPaginationTrigger(false);
        }).catch(err => {
            if (err) {
                if (err.response.status == 401) {
                    localStorage.clear();
                    window.location.href = '/';
                }
            }
            setShow("none")
        })
    }

    function startCall() {
        let param = {
            "receiver_id": otherUser,
            "receiver_name": localStorage.getItem('otherFriendChat'),
            "calltype": "S",
            "caller_id": user_id,
            "group_id": "0",
            "caller_name": username?.split(" ")[0] + " " + username?.split(" ")[1],
            "room_id": keyGen(),
            "call_event": "Start Call"
        }
        if (!otherUser) {
            toast.error("An error occured.")
            return false
        }
        if (socket.current) {
            socket.current.emit("startcalls", param)
            socket.current.on("startcalls", (r: any) => {
                console.log(r)
                if (r.call_status == 1) {

                    setCallModal(true)
                    if (!jitsiRoom) {
                        setJitsiRoom(r.room_id)
                    }
                } else {
                    toast.error(r.error_msg, { hideProgressBar: true })
                }
            })
        }
    }

    function keyGen() {
        var i, key = "", characters = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";

        var charactersLength = characters.length;

        for (i = 0; i < 10; i++) {
            key += characters.substr(Math.floor((Math.random() * charactersLength) + 1), 1);
        }
        var dt = new Date().getTime()
        return key + String(dt);
    }

    function msgType(msg: any) {
        if (msg.message_type === 'F') {
            var thumbpath = msg.thumbpath
            if (msg.message.toLowerCase().endsWith("png") || msg.message.toLowerCase().endsWith("jpg") || msg.message.toLowerCase().endsWith("jpeg")) {
                return (
                    <button type="button" className="btn btn-link" onClick={() => handlePlay(msg.message)}>
                        <img width={200} src={msg.message} alt="message" className="message-image" />
                    </button>
                )
            }
            if (msg.message.toLowerCase().endsWith("doc") || msg.message.toLowerCase().endsWith("docx")) {
                return (
                    <a href={msg.message} target="_blank" rel="noreferrer noopener">
                        <img width={150} src={require('../images/word.png').default} alt="word" />
                    </a>
                )
            }
            if (msg.message.toLowerCase().endsWith("xls") || msg.message.toLowerCase().endsWith("xlsx")) {
                return (
                    <a href={msg.message} target="_blank" rel="noreferrer noopener">
                        <img width={150} src={require('../images/excel.png').default} alt="excel" />
                    </a>
                )
            }
            if (msg.message.toLowerCase().endsWith("ppt") || msg.message.toLowerCase().endsWith("pptx")) {
                return (
                    <a href={msg.message} target="_blank" rel="noreferrer noopener">
                        <img width={150} src={require('../images/ppt.png').default} alt="ppt" />
                    </a>
                )
            }
            if (msg.message.toLowerCase().endsWith("pdf")) {
                return (
                    <a href={msg.message} target="_blank" rel="noreferrer noopener">
                        <img width={150} src={require('../images/pdf.jpg').default} alt="pdf" />
                    </a>
                )
            }
            if (msg.message.toLowerCase().endsWith("mov") || msg.message.toLowerCase().endsWith("mp4")) {
                return (
                    <button type="button" className="btn btn-link" onClick={() => handlePlay(msg.message)} style={{ position: "relative" }}>
                        <img width={80} className={msg.thumbnail ? msg.thumbnail === "undefined" ? "view-error-image" : "message-image" : "view-error-image"} title={msg.thumbnail ? msg.thumbnail === "undefined" ? "Thumbnail error" : msg.message.replace("https://dev-nlpbackend.xcellentlife.com/chat/", "") : "Thumbnail error"} src={msg.thumbnail ? msg.thumbnail : VideoError} alt="thumbnail" onError={(event: any) => { event.target.src = VideoError; }} />
                        <img width={80} className="play-box-bt" src={require('../images/play.png').default} alt="video" />
                    </button>
                )
            }
        }
        else {
            return (
                <p className="user_msg">{msg.message}</p>
            )
        }
    }

    function scrollToBottom() {
        var objDiv = document.getElementById('chatBox') as HTMLBodyElement;
        if (objDiv) {
            objDiv.scrollTo(0, objDiv.scrollHeight);
            // objDiv.scrollTo({
            //     top:objDiv.scrollHeight,
            //     left:0,
            //     behavior: "smooth"
            // });
            // objDiv.scrollTop = objDiv?.scrollHeight
        }
    }

    function handleCheckbox(e: any, msgId: string) {
        var tempmsgs = removeMsgs
        let checked = e.target.checked
        if (checked) {
            tempmsgs.push(msgId)
        } else {
            for (let index = 0; index < tempmsgs.length; index++) {
                const element = tempmsgs[index];
                if (element == msgId) {
                    tempmsgs.splice(index, 1)
                }
            }
        }
        setRemoveMsgs(tempmsgs)
        setTemp(temp + 1)
    }

    function handleRemoveMsgs() {
        if (!removeMsgs.length) {
            toast.error('Please select messages to delete.')
            return false
        }
        setShow("flex")
        var options = {
            "messsage_ids": removeMsgs.toString(),
            "room_id": Number(room),
        }
        socket.current.emit("deleteMessages", options)
        socket.current.on("deleteMessages", (recvData: any) => {
            setShow("none")
            var msgs = recvData.messsage_ids.split(",")
            var tempmsgs = data
            msgs.forEach((el: any) => {
                for (let index = 0; index < tempmsgs.length; index++) {
                    const element = tempmsgs[index];
                    if (element.id == el) {
                        tempmsgs.splice(index, 1)
                    }
                }
            });
            setData(tempmsgs)
            setRemoveMsgs([] as Array<any>)
            setTemp(0)
            scrollToBottom()
        })
        // setShow("none")
    }

    function sendMessage() {
        if (selectedFile.length) {
            handleFileUpload();
        } else {
            if (!sendMsg || sendMsg.trim() == "") {
                setMsg("")
                toast.error('Cannot send empty message.')
                return false
            }
            var options = {
                "message": sendMsg.trim(),
                "firstname": username?.split(" ")[0],
                "room_id": Number(room),
                "receiver_id": Number(otherUser),
                "message_type": "T",
                "profileImage": localStorage.getItem("profileImage"),
                "sender_id": Number(user_id)
            };
            socket.current.emit("message", options)
            socket.current.on("message", (recvData: any) => {
                let recvMsgId = recvData.id;
                var temp = data;
                var findMsg = temp.find((el: any) => el.id == recvMsgId);
                if (!findMsg) {
                    recvData.timeAgo = 'just now';
                    temp.push(recvData);
                    setData(temp);
                    scrollToBottom();
                }
            })
            let messageText = document.getElementById("message_textarea") as HTMLInputElement;
            if (messageText) {
                messageText.value = " ";
            }
            setMsg("");
        }
    }

    function handleKeyPress(e: any) {
        console.log('e.which', e.isComposing);
        //   Important code DO not remove
        // if (e.which == 13) {
        //     sendMessage();
        // }

        if (e.keyCode === 13) {
            if (e.ctrlKey) {
                console.log('ctrl+enter');
                let messageText = document.getElementById("message_textarea") as HTMLInputElement;
                if (messageText) {
                    messageText.value = messageText.value + "\n";
                }
                // let text=sendMsg+"\n";
                // setMsg(text);

            } else {
                sendMessage();
            }
        }
    }

    function handleFile(e: any) {
        let validExts = ["png", "jpg", "jpeg", "doc", "docx", "xls", "xlsx", "ppt", "pptx", "pdf", "mov", "mp4"]
        if (e.target.value) {
            let fName = e.target.files[0].name
            var ext = fName.split(".").at(-1).toLowerCase()
            var findExt = validExts.find((el: any) => el == ext)
            if (!findExt) {
                toast.error("Only png, jpg, jpeg, doc, docx, xls, xlsx, ppt, pptx, pdf, mov, mp4 formats are allowed");
                // toast.error("Please select a valid file. Selected file type is not allowed.")
                e.preventDefault()
            } else {
                returnFileType(e.target.files);
                setSelectedFile(e.target.files);
            }
        } else {
            toast.error("Please upload a file.")
        }
    }


    function returnFileType(e: any) {
        let fName = e[0].name;
        let ext = fName.split(".").at(-1).toLowerCase();
        if (returnFileTypeExtension(ext) === "image" || returnFileTypeExtension(ext) === "video") {
            var reader = new FileReader();
            reader.onload = function (event: any) {
                // imageUrl= event.target.result;
                if (returnFileTypeExtension(ext) === "video") {
                    setSelectedVideo(event.target.result);
                    // toggleVideoPreview(true);
                    triggerPopUpModal(true, 'file_upload');
                } else {
                    setSelectedImage(event.target.result);
                }
            }
            reader.readAsDataURL(e[0]);
        }
        if (returnFileTypeExtension(ext) === "doc") {
            setSelectedImage(require('../images/word.png').default);
        }
        if (returnFileTypeExtension(ext) === "xls") {
            setSelectedImage(require('../images/excel.png').default);
        }
        if (returnFileTypeExtension(ext) === "ppt") {
            setSelectedImage(require('../images/ppt.png').default);
        }
        if (returnFileTypeExtension(ext) === "pdf") {
            setSelectedImage(require('../images/pdf.jpg').default);
        }
        // if (returnFileTypeExtension(ext) === "video") {
        //     setSelectedImage(require('../images/videocall.svg').default);
        // }
    }

    function returnFileTypeExtension(ext: any) {
        if (['png', 'jpg', 'jpeg', 'gif'].includes(ext)) {
            return "image";
        }
        if (["doc", "docx"].includes(ext)) {
            return "doc";
        }
        if (["xls", "xlsx"].includes(ext)) {
            return "xls";
        }
        if (["ppt", "pptx"].includes(ext)) {
            return "ppt";
        }
        if (["pdf"].includes(ext)) {
            return "pdf";
        }
        if (["mov", "mp4"].includes(ext)) {
            return "video"
        }
    }

    function handleFileUpload() {
        setShow("flex")
        let formData = new FormData();
        formData.append("file", selectedFile[0])
        formData.append("user_id", user_id)
        userService.uploadFile(formData).then(resp => {
            var uploadedFile = resp.data.data.filepath
            // e.target.value = ""
            var options = {
                "message": uploadedFile,
                "firstname": username?.split(" ")[0],
                "room_id": Number(room),
                "receiver_id": Number(otherUser),
                "message_type": "F",
                "profileImage": localStorage.getItem("profileImage"),
                "sender_id": Number(user_id)
            } as any;
            if (resp.data.data.thumbpath || resp.data.data.thumbshortpath) {
                options.thumbnail = resp.data.data.thumbpath ? resp.data.data.thumbpath : resp.data.data.thumbshortpath
            }
            socket.current.emit("message", options)
            socket.current.on("message", (recvData: any) => {
                let recvMsgId = recvData.id;
                var temp = data;
                var msg = recvData.message;
                var findMsg = temp.find((el: any) => el.id == recvMsgId);
                if (!findMsg) {
                    if (msg.endsWith("mp4")) {
                        recvData.thumbpath = msg.replace("mp4", "png");
                    }
                    if (msg.endsWith("mov")) {
                        recvData.thumbpath = msg.replace("mov", "png");
                    }
                    recvData.timeAgo = 'just now';
                    temp.push(recvData);
                    setShow("none");
                    setData(temp);
                    setMsg("");
                    // e.target.value = ""
                    scrollToBottom();
                }
            })
            removeFile();
        }).catch(err => {
            if (err.response.status == 401) {
                localStorage.clear()
                window.location.href = '/'
            }
            console.log("error");
            toast.error("An error occured while uploading file.")
            setShow("none")
        })

    }

    const handleJitsiIFrameRef = (iframeRef: any) => {
        iframeRef.style.border = '10px solid cadetblue';
        iframeRef.style.background = 'cadetblue';
        iframeRef.style.height = '450px';
    };

    function handleApiReady(apiObj: any, ref: any) {
        ref.current = apiObj;
        // apiObj.addModerator()
        console.log('apiObj', ref.current)
        ref.current.addEventListener("readyToClose", function (props: any) {
            socket.current.emit("endcall", { room_id: jitsiRoom, calltype: "S", caller_id: user_id, call_event: "End Call" })
            socket.current.on("endcall", (resp: any) => {
                if (resp.call_status == 1) {
                    setCallModal(false)
                    setJitsiRoom("")
                }
            })
        })

        ref.current.addEventListener("participantLeft", function (props: any) {
            socket.current.emit("endcall", { room_id: jitsiRoom, calltype: "S", caller_id: user_id, call_event: "End Call" })
            socket.current.on("endcall", (resp: any) => {
                if (resp.call_status == 1) {
                    setCallModal(false)
                    setJitsiRoom("")
                }
            })
        })
    }


    function confirmDeleteMessage() {
        Swal.fire({
            text: `Are you sure you want to delete the selected message${removeMsgs.length > 1 ? "(s)" : ""}.`,
            title: "Alert",
            showCancelButton: true,
            confirmButtonText: "Delete"
        }).then(e => {
            if (e.value) {
                handleRemoveMsgs();
            }
        })
    }

    function handlePlay(url: any) {
        setPlay(url);
        triggerPopUpModal(true, 'message_media_view');
    }

    function removeFile() {
        setSelectedFile({});
        setSelectedImage('');
        // toggleVideoPreview(false);
        triggerPopUpModal(false, '');
        setSelectedVideo('');
    }

    useEffect(() => {
        if (selectedImage) {
            triggerImage();
        }
    }, [selectedImage]);

    function triggerImage() {
        Swal.fire({
            title: selectedImage.includes("video") ? "" : 'Preview Image',
            imageUrl: selectedImage,
            imageAlt: 'Custom image',
            customClass: {
                image: 'imagePreviewmessage'
            },
            showCancelButton: true,
            confirmButtonText: "Send"
        }).then(e => {
            if (e.value) {
                sendMessage();
            } else {
                removeFile();
            }
        })
    }

    function handleMessageSubmit(event: any) {
        event.preventDefault();
        sendMessage();
    }

    function onKeyDown(event: any) {
        console.log('onKeyDown...', event.which);
        // if(event.which===16){
        //     setShiftkey(event.which);
        // }else{
        //     setShiftkey('')
        // }
    }

    function onKeyup(event: any) {
        // console.log('onKeyup...',event.which,shiftKey);
        // if(shiftKey ===16&& event.which===13){
        //     setShiftkey('')
        // }else{
        //     if(event.which===13){
        //         sendMessage();
        //     }
        // }
    }

    // common function for popup modal
    function triggerPopUpModal(status: Boolean, type: any) {
        setPopUpModal((prevPopUpModal: any) => {
            return {
                ...prevPopUpModal,
                status: status,
                modalType: type
            }
        });
    }

    // Common function for handling pagination
    function handlePagination(type: any, value: any) {
        setPaginationObj((previousPaginationObj: any) => {
            return {
                ...previousPaginationObj,
                [type]: value
            }
        })
    }

    function handleScrollInput(event: any) {
        console.log('scrollHeight', event.target.scrollHeight);
        console.log('offsetHeight', event.target.offsetHeight);
        console.log('scrollTop', event.currentTarget.scrollTop);

        if (event.currentTarget.scrollTop === 0) {
            if (paginationObj.page_no + 1 < paginationObj.total_page) {
                setShow("flex");
                setIsPaginationTrigger(true);
                handlePagination('page_no', paginationObj.page_no + 1);
            }
        }

    }

    useEffect(() => {
        if (isPaginationTrigger) {
            getChat({
                other_id: otherUser,
                page_no: `${paginationObj.page_no}`
            });
        }
    }, [isPaginationTrigger]);

    let modalProps = {
        play: play,
        selectedVideo: selectedVideo
    },
        modalFuncProps = {
            sendMessage: sendMessage
        };
    console.log('otherUser', otherUser === "1");



    return (
        <>
            <PopUpModal
                status={popUpModal.status}
                modalType={popUpModal.modalType}
                onClose={triggerPopUpModal}
                {...modalProps}
                {...modalFuncProps}
            />
            <section className="bodyvital_section py-5">
                <Loader status={show} />
              
                <Container>
                    <div className="inner_box pb-0">
                        {showCall
                            ?
                            <Row>
                                <Col md={12} className="mb-3">
                                    {/* <JitsiMeeting configOverwrite={jitsiConfig}
                                     interfaceConfigOverwrite={interfaceJitsi} 
                                     roomName={jitsiRoom} 
                                     userInfo={{ displayName: username, 
                                        email: "" ,
                                       
                                     }} 
                          
                                     getIFrameRef={handleJitsiIFrameRef} domain="meet.jit.si" onApiReady={externalApi => handleApiReady(externalApi, apiRef)} 
                                    
                                     /> */}
                                     
                                    <JitsiMeeting
                                        domain={'https://dev-nlp.xcellentlife.com'}
                                        roomName={jitsiRoom}
                                        configOverwrite={{
                                            startWithAudioMuted: true,
                                            disableModeratorIndicator: true,
                                            enableWelcomePage: false,
                                            prejoinPageEnabled: false,
                                            enableUserRolesBasedOnToken: false,
                                            lockRoomGuestEnabled: false,
                                            startAudioOnly: false, 
                                            startWithVideoMuted: true, 
                                            requireDisplayName: false,
                                            autoKnockLobbyEnabled: false, 
                                            disableInviteFunctions: true,
                                            enableClosePage: true,
                                        }}
                                        
                                        interfaceConfigOverwrite={{
                                            DISABLE_JOIN_LEAVE_NOTIFICATIONS: true,
                                            DISABLE_FOCUS_INDICATOR: false,
                                            AUTHENTICATION_ENABLE: false
                                            
                                        }}
                                        userInfo={{
                                            displayName: username,
                                            email: ''
                                        }}
                                        onApiReady={(externalApi) => handleApiReady(externalApi, apiRef)}
                                        getIFrameRef={(iframeRef) => { iframeRef.style.height = '400px'; }}
                                    />
                                </Col>
                            </Row>
                            : ""
                        }
                        <Row className="bodyvitals_head">
                            <Col md={6}>
                                <h5>
                                    <span onClick={() => { history.go(-1) }}>
                                        <img src={require('../images/back.svg').default} alt="back" />
                                    </span>
                                </h5>
                                <h2>
                                    {localStorage.getItem('otherFriendChat')}
                                </h2>
                            </Col>
                            <Col md={6} className="d-flex justify-content-end align-items-center">
                                {
                                    removeMsgs.length ?
                                        <span className="newrqst1 mr-4">
                                            {`Selected ${removeMsgs.length} ${removeMsgs.length > 1 ? 'messages' : 'message'}`}
                                        </span>
                                        : ''
                                }
                                {removeMsgs.length ?
                                    <h5 onClick={confirmDeleteMessage} className="c-pointer">
                                        <i style={{ border: "1px solid lightgrey", padding: "15px 19px", borderRadius: "100%" }} className="fa fa-trash nextarrow mx-1 text-danger" />
                                    </h5>
                                    : ""
                                }

                                {
                                    otherUser === "1" ? "" :
                                        !showCall ?
                                            <span className="border-0 bg-transparent mx-1 chat-module" onClick={() => { startCall() }}>
                                                <img src={require('../images/videocall.svg').default} alt="videocall" />
                                            </span>
                                            : ""
                                }

                                {
                                    data.created_id == Number(user_id) ?
                                        <Link className="border-0 bg-transparent mx-1" to="#/">
                                            <img src={require('../images/edit_icon.svg').default} alt="transparent" />
                                        </Link>
                                        : ""
                                }
                            </Col>
                            <Col md={12}>
                                <hr />
                            </Col>
                        </Row>
                       
                        <Row>
                            <div className="chat_row" ref={chatRowRef}>
                                <div className="chat_box" id="chatBox" onScroll={(event: any) => handleScrollInput(event)}>
                                    {
                                        data.length
                                            ?
                                            data.map((d: any, i: any) => {
                                                return (
                                                    <React.Fragment key={`${d.id}_${i}`}>
                                                        {
                                                            d.sender_id == user_id
                                                                ?
                                                                <div className="leftside_chat right_side_user">
                                                                    <div className="user_name_p">
                                                                        <h5 className="text-right">You</h5>
                                                                        <div className="check-chk">
                                                                            {msgType(d)}
                                                                            <Form>
                                                                                <Form.Control checked={removeMsgs.includes(d.id) ? true : false} onChange={(e: any) => { handleCheckbox(e, d.id) }} type="checkbox" className="chk" />
                                                                            </Form>
                                                                            {/* <div className="user_img"><img src={d.profileImage} alt="" /></div> */}
                                                                        </div>
                                                                        <div className="check-chk">

                                                                            <div className="user_img right"><img src={d.profileImage} alt="right" /></div>
                                                                            <p className="msg_time text-left">{d.timeAgo}</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                :
                                                                <div className="leftside_chat">
                                                                    <div className="user_img"><img src={d.profileImage} alt="leftside_chat" /></div>
                                                                    <div className="user_name_p">
                                                                        <h5>{d.firstname}</h5>
                                                                        {msgType(d)}
                                                                        <p className="msg_time">{d.timeAgo}</p>
                                                                    </div>
                                                                </div>
                                                        }
                                                    </React.Fragment>
                                                )
                                            })
                                            :
                                            <div className="no_chat_box">
                                                <img src={require('../images/no_msg.svg').default} alt="no_msg" />
                                            </div>
                                    }
                                </div>
                                {/* {
                                    selectedFile.length ?
                                        <div className="selected_file_section" id="selectedFile">
                                            <div className='selected_image'>
                                                <i className="fa fa-times" aria-hidden="true" onClick={() => removeFile()} />
                                                <img src={selectedImage} alt="file" />
                                            </div>
                                        </div>
                                        : ''
                                } */}
                                <div className="send_message_row">
                                    <form autoComplete='off' id="messageCompose" onSubmit={(event: any) => handleMessageSubmit(event)}>
                                        <span>
                                            <img src={require('../images/add.svg').default} alt="add" />
                                            <input type="file" className="custom-form-file" onClick={(e: any) => { e.target.value = "" }} onChange={(e: any) => { handleFile(e) }} />
                                        </span>
                                        {/* onKeyUp={(e: any) => { handleKeyPress(e) }} onKeyDown={(e: any) => { handleKeyPress(e) }} */}
                                        <textarea value={sendMsg} id="message_textarea" placeholder="Enter Your Message" onKeyDown={(e: any) => { handleKeyPress(e) }} onChange={(e: any) => { setMsg(e.target.value) }} />
                                        <button type="submit" className="btn pr-3"><img src={require('../images/send.svg').default} alt="send" /></button>
                                    </form>
                                </div>
                            </div>
                        </Row>
                    </div>
                </Container>
            </section>
        </>
    )
}
export default FriendChat;