import { useState, useEffect } from 'react';
import { Container, Row, Col, Modal } from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';
import { userService } from '../_services';
import { toast } from 'react-toastify';
import { LinkedinShareButton, TwitterShareButton, FacebookShareButton } from "react-share";
import { Loader } from './Loader';
import { deepLink } from '../config/config';
import moment from 'moment';
import ProfileImage from '../images/profile.png';
import Swal from 'sweetalert2';


const Competitiondetails = () => {
    const [showloader, setShowloader] = useState({ display: "none" });
    const [data, setData] = useState({}) as any;
    const [compId, setCompId] = useState("") as any;
    const [play, setPlay] = useState("") as any;
    const [modalStatus, setModalStatus] = useState(false);
    const [appLink, setAppLink] = useState('');
    const [socialMedia, setSocialMedia] = useState('');
    const [inviteUserModal, setInviteUserModal] = useState(false);
    const [activeId, setActiveId] = useState([]) as any;
    const [invitedUser, setInvitedUser] = useState([]) as any;
    const [ads, setAds] = useState([]) as any;
    const [searchUser, setSearchUser] = useState("") as any;
    const [isSearchTrigger, setIsSearchTrigger] = useState(false) as any;

    const IMAGE_REGEX = (/\.(gif|jpe?g|tiff?|png|webp|bmp|jfif)$/i);

    let history = useHistory();
    let user_id = localStorage.getItem("user_id");

    useEffect(() => {
        triggerLoader("flex");
        let search = window.location.search;
        let params = new URLSearchParams(search);
        let comp_id = params.get("comp_id") as any;
        let txn_id = params.get("transaction_id");
        let paySuccess = params.get("success");
        if (!comp_id) {
            toast.error("Error while loading competition.");
            history.push("/competition");
        }
        setCompId(comp_id);
        localStorage.setItem("comp_id", comp_id);
        userService.competitionDetails(comp_id).then(resp => {
            setData(resp.data.data);
            setAds(resp.data.data.ad_images);
            triggerLoader("none");
            // check if competition is completed
            if (moment().format('YYYY-MM-DD') <= resp.data.data.end_date) {
                generateDynamicLink(`${window.location.origin}/signup?competitionID=${comp_id}&referralCode=${resp.data.data.app_link}`, 'socialMedia');
                // generateDynamicLink(`${process.env.REACT_APP_DOWNLOAD_APP_LINK}${resp.data.data.app_link}`, 'appUrl');
            }
            setAppLink(`${process.env.REACT_APP_DOWNLOAD_APP_LINK}${resp.data.data.app_link}`)
        }).catch(err => {
            triggerLoader("none");
            if (err.response.status == 401) {
                localStorage.clear();
                window.location.href = '/';
            }
            if (err.response.status === 422) {
                history.push("/competition");
                toast.error(err.response.data.message);
            }
        })
        if (paySuccess == "1") {
            var item = localStorage.getItem('paymentDetails') as any
            var paymentObj = JSON.parse(item)
            console.log(paymentObj.payer_id, user_id)
            if (paymentObj.payer_id == user_id && paymentObj.event_id == comp_id) {
                var payload = {
                    discount_code: paymentObj.discount_code,
                    payment_id: paymentObj.paymentId,
                    status: "J",
                    transaction_id: txn_id,
                    competition_id: paymentObj.event_id
                }
                userService.joinCompetition(payload).then(resp => {
                    console.log(resp);
                    if (resp.data.status == true) {
                        toast.success('Payment completed successfully.');
                        localStorage.removeItem("paymentDetails");
                    } else {
                        toast.error(resp.data.message);
                        history.push('/competition');
                    }
                }).catch(error => {
                    if (error.response.status == 401) {
                        localStorage.clear();
                        window.location.href = '/';
                    }
                })
            } else {
                toast.error('Invalid payment details')
                history.push('/competition')
            }
        }
    }, [])

    function socialShare(p: any, type: any) {
       
        let params = {
            "share_type": "C",
            "sharing_platform": p,
            "competition_id": String(compId),
            "receiver": "",
            "referral_code": localStorage.getItem('referCode') as any
        }
        if (type === "invite") {
            let string = "" as any;
            activeId.forEach((value: any, index: any) => {
                let symbol = activeId.length - 1 === index ? "" : ","
                string += value + symbol;
            })
            params.receiver = string;
        }
        userService.shareApp(params).then(resp => {
            if (type === "invite") {
                triggerInviteUserModal(false);
                setActiveId([]);
                triggerLoader("none");
                toast.success("Shared Successfully");
            }
        }).catch(err => {
            triggerLoader("none");
            console.log(err)
        })
    }

    function returnPointString(value: any) {
        return `${value} ${parseInt(value) > 1 ? 'Points' : 'Point'}`
    }

    function handlePlay(url: any) {
        setPlay(url);
        toggleModal(true);
    }

    function toggleModal(status: any) {
        setModalStatus(status);
    }

    function handleCopyText(text: string) {
        if (navigator.clipboard) {
            navigator.clipboard.writeText(text).then(() => {
                toast.success('Referral code copied.', { autoClose: 2500, hideProgressBar: true });
            }).catch((error) => {
                toast.error(error);
            })
        } else {
            toast.error('Something went wrong');
        }
    }

    function back() {
        if (moment().format('YYYY-MM-DD') <= data.end_date) {
            let viaNotification = localStorage.getItem("viaNotification");
            if (viaNotification === "true") {
                history.go(-1);
                localStorage.removeItem('viaNotification');
            } else {
                history.push(`/competition`);
                localStorage.setItem("compTab", 'joined');
            }
        } else {
            history.go(-1);
            localStorage.setItem("compTab", 'completed');
        }
    }


    function generateDynamicLink(url: any, type: string) {
        let data = {
            ...deepLink
        } as any;
        data.dynamicLinkInfo.link = url;
        console.log('REACT_APP_DOWNLOAD_APP_LINK',url)
        userService.createDynamicLink(data).then(resp => {
            console.log('dattaaa',data)
            if (type === "socialMedia") {
               
                setSocialMedia(resp.data.shortLink);
            }
            if (type === "appUrl") {
                setAppLink(resp.data.shortLink);
            }
        }).catch(err => {
            setAppLink('');
        })
    }

    function submitInviteUserForm(event: any) {
        event.preventDefault();
        triggerLoader("flex");
        socialShare("APP_USER", "invite");
    }


    function triggerInviteUserModal(status: any) {
        setInviteUserModal(status);
        setInvitedUser('');
        setSearchUser('')
    }

    function inviteUserListing() {
        userService.addFriendListing({ "name": '' }).then(resp => {
            setInvitedUser(resp.data.data);
            setSearchUser('');
            setIsSearchTrigger(false);
            triggerLoader("none");
        }).catch(err => {
            triggerLoader("none");
            if (err.response.status == 401) {
                localStorage.clear()
                window.location.href = '/'
            }
        })
    }


    function toggleListingCheck(id: any, status: any) {
        if (status) {
            activeId.push(id);
        } else {
            activeId.splice(activeId.indexOf(id), 1);
        }
        setActiveId([...activeId])
    }

    function handleSearchInput(event: any) {
        let { value } = event.target;
        setSearchUser(value);
    }

    function handleSearchSubmit(event: any) {
        event.preventDefault();
        let data = invitedUser.filter((x: any) => {
            let fname = x.firstname + ' ' + x.lastname;
            if (fname.trim().toLocaleLowerCase().includes(searchUser.trim().toLocaleLowerCase())) {
                return x;
            }
        });
        setInvitedUser(data);
        setIsSearchTrigger(true);
    }

    function triggerLoader(status: any) {
        setShowloader(prevShowLoader => {
            return {
                ...prevShowLoader,
                display: status
            }
        })
    }

    function loadInviteUser() {
        triggerLoader("flex");
        inviteUserListing();
        triggerInviteUserModal(!inviteUserModal)
    }

    function triggerImage(url:any) {
        Swal.fire({
            title:'',
            imageUrl: url,
            imageAlt: 'Custom image',
            customClass:{
                image:'imagePreviewmessage'
            },
            showCancelButton: false,
            showConfirmButton:false,
            showCloseButton:true
        }).then(e => {
            console.log('Sweetalert',e)
        })
    }

    let facebookQuote = `Hi,Please click the link to join the ${data.title} competition - Also fill the referral code - ${data.app_link}`;

    return (
        <>
            <section className="bodyvital_section py-5">.
                <Loader status={showloader.display} />
                <Container>
                    <div className="inner_box pb-5">
                        <Row className="bodyvitals_head">
                            <Col md={12}>
                                <h5>
                                    <span onClick={back}>
                                        <img src={require('../images/back.svg').default} alt="back" />
                                    </span>
                                </h5>
                                <h2>{data.title}</h2>
                            </Col>
                            <Col md={12}>
                                <hr />
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={8} md={6} className="mb-3">
                                <div className="gorup_Details_head comp_details_head text-left" style={{ margin: "unset" }}>
                                    <div className="user_image_row1 c-pointer" onClick={() => { history.push(`/details?improve=true&comp_id=${compId}`) }}>
                                        <img style={{ width: "100%", height: "100%", objectFit: "cover" }} src={data.comp_image} alt="cover" />
                                    </div>
                                    <div className="d-inline-block ml-3">
                                        <p>Start date: <span>{moment(data.start_date).format('MM/DD/YYYY')}</span></p>
                                        <p>End date: <span>{moment(data.end_date).format('MM/DD/YYYY')}</span></p>
                                    </div>
                                </div>
                            </Col>
                            {
                                moment().format('YYYY-MM-DD') <= data.end_date ?
                                    <Col lg={4} md={6} className="text-right">
                                        <h3 className="invite_text">Invite User</h3>
                                        <div className="competition_detail_invite">
                                            <button type="button" className='btn btn-link invite_user_btn' onClick={() => loadInviteUser()}>
                                                <img src={require("../images/Icon-App-83.5x83.5@2x.png").default} alt="report" className='invite_site_user' />
                                            </button>
                                            <button type="button" className="btn btn-link">
                                                {console.log('socialMedia',socialMedia)}
                                                <FacebookShareButton
                                                    onShareWindowClose={() => { socialShare("FB", "social") }}
                                                    url={socialMedia}
                                                    quote={facebookQuote}>
                                                    <img src={require('../images/invite_fb.svg').default} alt="invite_fb" />
                                                </FacebookShareButton>
                                            </button>
                                            <button type="button" className="btn btn-link">
                                                <TwitterShareButton
                                                    onShareWindowClose={() => { socialShare("TWITTER", "social") }}
                                                    title={"Here is the link to " + data.title + " on XcellentLife."}
                                                    url={socialMedia}>
                                                    <img src={require('../images/invite_twitter.svg').default} alt="invite_twitter" />
                                                </TwitterShareButton>
                                            </button>
                                            <button type="button" className="btn btn-link">
                                                <LinkedinShareButton
                                                    onShareWindowClose={() => { socialShare("LINKEDIN", "social") }}
                                                    title={"Here is the link to " + data.title + " on XcellentLife."}
                                                    url={socialMedia}>
                                                    <img src={require('../images/invite_link.svg').default} alt="invite_link" />
                                                </LinkedinShareButton>
                                            </button>
                                        </div>
                                    </Col>
                                    : ''
                            }
                        </Row>
                        <Row className="mt-4 comp_details_area">
                            <Col md={12} className="mb-0"><h2 className="mb-1">Details</h2><hr /></Col>
                            <Col lg={6} md={12}>
                                <Row>
                                    <Col lg={6} md={12}>
                                        <div className="details_label">
                                            <h3 className="mb-0">Entry Fee</h3>
                                            <h3 className="mb-0">${data.entry_fee}</h3>
                                        </div>
                                    </Col>
                                    <Col lg={6} md={12}>
                                        <div className="details_label">
                                            <h3 className="mb-0">Award Money</h3>
                                            <h3 className="mb-0">${data.award_money}</h3>
                                        </div>
                                    </Col>
                                </Row>
                                <div className="points_section mt-4">
                                    <h2>Points system</h2>
                                    <div className="points_box">
                                        <Row className="align-items-center">
                                            <Col md={4}>
                                                <h4>Facebook Share</h4>
                                            </Col>
                                            <Col md={8}>
                                                <h5>(remainingTime on Timer)  X {returnPointString(data.facebook_share_point)}</h5>
                                            </Col>
                                            <Col md={12}>
                                                <hr className="my-0" />
                                            </Col>
                                            <Col md={4}>
                                                <h4>Twitter Share</h4>
                                            </Col>
                                            <Col md={8}>
                                                <h5>(remainingTime on Timer)  X {returnPointString(data.twitter_share_point)}</h5>
                                            </Col>
                                            <Col md={12}>
                                                <hr className="my-0" />
                                            </Col>
                                            <Col md={4}>
                                                <h4>Linkedin Share</h4>
                                            </Col>
                                            <Col md={8}>
                                                <h5>(remainingTime on Timer)  X {returnPointString(data.linkedin_share_point)}</h5>
                                            </Col>
                                            {/* <Col md={12}>
                                                <hr className="my-0" />
                                            </Col> */}
                                            {/* <Col md={4}>
                                                <h4>Instagram Share</h4>
                                            </Col>
                                            <Col md={8}>
                                                <h5>(remainingTime on Timer)  X {returnPointString(data.instagram_share_point)}</h5>
                                            </Col> */}
                                            {data.survey_point ? <>
                                                <Col md={12}>
                                                    <hr className="my-0" />
                                                </Col>
                                                <Col md={4}>
                                                    <h4>Survey Points</h4>
                                                </Col>
                                                <Col md={8}>
                                                    <h5>{returnPointString(data.survey_point)}</h5>
                                                </Col>
                                            </> : ''}
                                            <Col md={12}>
                                                <hr className="my-0" />
                                            </Col>
                                            <Col md={9}>
                                                <h4 className="mb-1 pb-0">App Downloads</h4>
                                                <p className="pb-2">(Each Time Someone Users Your Download Link)</p>
                                            </Col>
                                            <Col md={3}>
                                                <h5>{returnPointString(data.app_download_point)}</h5>
                                            </Col>
                                            <Col md={12}>
                                                <hr className="my-0" />
                                            </Col>
                                            <Col md={8}>
                                                <h4 className="mb-1 pb-0">Referral Code</h4>
                                                <p className="pb-2">(Each Time Someone Users Your Download Link)</p>
                                            </Col>
                                            <Col md={4}>
                                                <h5>{returnPointString(data.discount_code_point)}</h5>
                                            </Col>
                                            <Col md={12}>
                                                <hr className="my-0" />
                                            </Col>
                                            <Col md={8}>
                                                <h4 className="minimum_points">Minimum Points Required to Qualify Award</h4>
                                            </Col>
                                            <Col md={4}>
                                                <h5>{returnPointString(data.min_point)}</h5>
                                            </Col>
                                            <Col md={12}>
                                                <hr className="my-0" />
                                            </Col>
                                            <Col md={8}>
                                                <h4 className="minimum_points">Maximum Wellness Points</h4>
                                            </Col>
                                            <Col md={4}>
                                                <h5>{returnPointString(data.maximum_wellness_points)}</h5>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            </Col>
                            <Col lg={6} md={12} className="details_rightside">
                                <Link className="d-flex justify-content-between align-items-center" to={"/leaderboard?comp_id=" + compId}>
                                    <h3 className="mb-0">Leader Board</h3>
                                    <i className="fa fa-angle-right nextarrow" />
                                </Link>
                                <div><hr className="my-3" /></div>
                                <div>
                                    <h3 className="mb-2">Ad</h3>
                                    {
                                        ads.length ?
                                            <div className="row">
                                                {
                                                    ads.map((value: any, index: any) => {
                                                        let extension = value.attachment_url.split('/').pop().split('.').pop();
                                                        return (
                                                            <div className="col-md-4 mb-2" key={`${value.id}_${index}`}>
                                                                {
                                                                    IMAGE_REGEX.test(`.${extension}`) ?
                                                                        <img src={value.attachment_url} className="comp_ad_media" alt={`competition_${index}`} onClick={() =>triggerImage(value.attachment_url)
                                                                            //  handlePlay(value.attachment_url)
                                                                            } /> :
                                                                        <div className='play-box' onClick={() => handlePlay(value.attachment_url)}>
                                                                            <i className={`fa fa-play`} aria-hidden="true" />
                                                                            <video src={value.attachment_url} id={value.id} className="thumb_video" autoPlay={false} controls={false} width="250px" height="100px" />
                                                                        </div>
                                                                }
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                            : `No data found`
                                    }
                                    {/* <img src={ads.length?ads[0].attachment_url:require('../images/mic.gif').default} alt={ads.length?"attachment_url":"mic"} width="250px" /> */}
                                    {/* {ads.length?<img src={ads[0].attachment_url} alt="attachment_url" width="250px" />:<img src={require('../images/mic.gif').default} alt="mic" width="250px" />} */}
                                </div>
                                <div>
                                    <hr className="my-3" />
                                </div>
                                {
                                    appLink ?
                                        <div className='mt-2'>
                                            <h3 className="mb-2">Your App Download Link</h3>
                                            <a href={`${appLink}`} className="pointer-events-none" target="_blank" style={{ color: "#1AA0E6" }} rel="noreferrer noopener">{appLink}</a>
                                        </div>
                                        : ''
                                }
                                <div>
                                    <hr className="my-3" />
                                </div>
                                <div className="refral_row position-relative">
                                    <h3 className="mb-2">Your Referral Code</h3>
                                    <p style={{ color: "#1AA0E6" }} id="referral_code" className='copy-refrel'>
                                        {data.app_link}
                                    </p>
                                    <span onClick={() => { handleCopyText(data.app_link) }}>
                                        <i className="fa fa-copy copy-bt" />
                                    </span>
                                </div>
                            </Col>
                        </Row>
                        {moment().format('YYYY-MM-DD') <= data.end_date ? <div className="col-md-12 text-center mt-4">
                            <div className="btm_button">
                                <button className="sub_btn btn" onClick={() => {
                                    localStorage.setItem("left_menu_option", "fourth");
                                    localStorage.setItem('viaIsDetail', "true");
                                    history.push('/');
                                }} >Improve Your Wellness</button>
                            </div>
                        </div> : ''}
                    </div>
                </Container>
            </section>

            <Modal show={modalStatus} onHide={() => toggleModal(!modalStatus)} size="md" centered backdrop="static" keyboard={false}>
                <Modal.Header closeButton className="border-0 headerclose" />
                <Modal.Body className="text-center py-4">
                    <div className="container_fluid mt-4">
                        <div className="row">
                            <div className="col-md-12">
                                {IMAGE_REGEX.test(`.${play.split('/').pop().split('.').pop()}`) ? <img src={play} className="modal_image" alt={`competition`} /> : <video src={play} controls={true} autoPlay={false} className="modal_video" />}
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

            <Modal show={inviteUserModal} onHide={() => triggerInviteUserModal(!inviteUserModal)} size="md" centered backdrop="static" keyboard={false}>
                <Modal.Header closeButton >
                    <Modal.Title>Invite User</Modal.Title>
                </Modal.Header>
                <Modal.Body className="text-center">
                    <div className="container_fluid">
                        <form onSubmit={(event) => { handleSearchSubmit(event) }}>
                            <div className="search-form">
                                <input type="text" placeholder='Search User' value={searchUser} onChange={(event) => handleSearchInput(event)} required />
                                <div className='search-bt'>
                                    {
                                        isSearchTrigger ?
                                            <button type="button" onClick={() => { triggerLoader("flex"); inviteUserListing(); }}>Reset</button>
                                            : <button type="submit">Search</button>
                                    }
                                </div>
                            </div>
                        </form>
                        {
                            invitedUser.length ?
                                <form onSubmit={(event) => submitInviteUserForm(event)} autoComplete="off">
                                    <div className="scroll-box">
                                        {
                                            invitedUser.map((value: any, index: any) => {
                                                let status = activeId.includes(parseInt(value.id));
                                                return (
                                                    <div className="row cursor-pointer align-items-center border-box check-d-none" key={`${value.id}_${index}`} onClick={() => toggleListingCheck(value.id, !status)}>
                                                        <div className="col-md-2">
                                                            <div className='image-username'>
                                                                <img src={value.profileImage} alt="profile" onError={(event: any) => { event.target.src = ProfileImage; }} />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-8">
                                                            <span>{value.firstname} {value.lastname}</span>
                                                        </div>
                                                        <div className="col-md-2">
                                                            <div className="check-click">
                                                                {status ? <img src={require('../images/check.png').default}></img> : ''}
                                                                {/* <input name="group1" type="checkbox" id="reverse-checkbox-1" className="form-check-input" />
                                                    <label title="" htmlFor="reverse-checkbox-1" className="form-check-label" /> */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <button type="submit" className='btnsumit btn btn-unset mt-3 mb-0' disabled={activeId.length ? false : true}>Send</button>
                                        </div>
                                    </div>
                                </form>
                                : <div className="scroll-box">
                                    <div className="row cursor-pointer align-items-center border-box check-d-none">
                                        <div className="col-12 text-center mt-4 mb-4">
                                            No Data Available
                                        </div>
                                    </div>
                                </div>
                        }
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}
export default Competitiondetails;